
import PageComponent, { SubTitle, Title } from '../../components/Page';
import { MenuTypes } from '../../consts/menu';
import styles from './Local.module.scss';

const LocalPage = () => {

    return <PageComponent>
        <Title>{MenuTypes.consts.local.label}</Title>
        <SubTitle>Sítio Della Torre</SubTitle>
        <p>Escolhemos o <strong>Sítio Della Torre</strong> por ser ideal para um evento no campo, como queremos. Espaço amplo, bem arborizado, decorações rústicas e a <span className='text-nowrap'>apenas 30 minutos</span> de São Paulo.</p>
        <p>A equipe do Buffet prontamente se comprometeu a nos ajudar com um cardápio bem elaborado, delicioso e 100% vegano.</p>
        <p>O sítio está localizado na <a href="https://www.google.com.br/maps/place/S%C3%ADtio+Della+Torre/@-23.298758,-46.5694025,17z/data=!4m5!3m4!1s0x94ceedb3abfe6b33:0x147842871e75b3a7!8m2!3d-23.2992036!4d-46.5670108" target="_blank" rel="noreferrer">Estrada Municipal, 270 - Jardim Sandra, Mairiporã – SP</a>.</p>
        <p><img src="/img/sitio-della-torre.jpg" alt="Sítio Della Torre" className={styles.foto} /></p>
        <p>Quer saber mais sobre o espaço? <a href="https://www.sitiodellatorre.com.br/" target="_blank" rel="noreferrer">Clique aqui</a> e confira.</p>
    </PageComponent>
}

export default LocalPage;