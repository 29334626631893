import PageComponent, { Title } from "../../components/Page";
import { MenuTypes } from "../../consts/menu";
import styles from './PreWedding.module.scss';

const PreWeddingPage = () => {

    return <PageComponent>
        <Title>{MenuTypes.consts.preWedding.label}</Title>
        <iframe className={styles.video} src="https://www.youtube.com/embed/O1_Pz9vLDNU" title="YouTube video player" allowFullScreen={true} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </PageComponent>
}

export default PreWeddingPage;