const TOKEN_KEY = 'KK_CASAMENTO_TOKEN'

const lsToken = {
    set: (token: string): void => {
        localStorage.setItem(TOKEN_KEY, token)
    },
    get: (): string | null => {
        return localStorage.getItem(TOKEN_KEY)
    },
    remove: (): void => {
        localStorage.removeItem(TOKEN_KEY)
    }
}

export default lsToken