import classNames from "classnames";
import Divider from "../../components/Divider";
import useWindowSize from "../../hooks/useWindowSise";
import styles from "./Cardapio.module.scss";

const CardapioPage = () => {
  const windowSize = useWindowSize();

  return (
    <div className={styles.stage}>
      <div className={styles.cardapio}>
        <div
          className={classNames(styles.cantoRosas, styles.top)}
          style={{ backgroundImage: 'url("./img/canto-de-rosa.png")' }}
        ></div>
        <div
          className={classNames(styles.cantoRosas, styles.bottom)}
          style={{ backgroundImage: 'url("./img/canto-de-rosa.png")' }}
        ></div>
        <div className={styles.cardapioInner}>
          <h2 className={styles.title}>Cardápio</h2>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3 className={styles.itemTitle}>Entradas</h3>
              <ul>
                <li>Berinjela Empanada</li>
                <li>
                  Canapés
                  <ul>
                    <li>Caponata de Abobrinha</li>
                    <li>Caponata de Berinjela</li>
                    <li>Creme de Tomate Seco</li>
                    <li>Creme de Azeitona</li>
                  </ul>
                </li>
              </ul>
              <Divider />
              <h3 className={styles.itemTitle}>Finger food</h3>
              <ul>
                <li>Coxinha de Jaca</li>
                <li>Bolinho de Brócolis</li>
                <li>Bastão de Milho</li>
                <li>Bolinho de Proteína de Soja</li>
                <li>Quibe</li>
                <li>Risole de Palmito</li>
              </ul>
              <Divider />
              <h3 className={styles.itemTitle}>Jantar</h3>
              <ul>
                <li>
                  Festival de Massas
                  <ul>
                    <li>Penne</li>
                    <li>Parafuso</li>
                    <li>Talharim</li>
                    <li>Fusilli</li>
                  </ul>
                </li>
                <li>
                  Acompanhamentos
                  <ul>
                    <li>Palmito</li>
                    <li>Milho</li>
                    <li>Azeitona</li>
                    <li>Tomate Secos</li>
                    <li>Abobrinha Ralada</li>
                    <li>Brócolis</li>
                    <li>Ervilha</li>
                    <li>Tomate Cereja</li>
                    <li>Cebolinha</li>
                    <li>Salsinha</li>
                  </ul>
                </li>
                <li>
                  Molhos
                  <ul>
                    <li>Sugo</li>
                    <li>Pesto</li>
                  </ul>
                </li>
                <li>Spaguetti ao Alho e Óleo</li>
              </ul>
              <Divider />
              <h3 className={styles.itemTitle}>Ilha de acompanhamentos</h3>
              <ul>
                <li>Risoto Funghi</li>
                <li>Batata Souté</li>
                <li>Mandioca Frita</li>
                <li>Grão de Bico</li>
                <li>Tabule</li>
                <li>Salada de Folhas</li>
                <li>Batata Doce</li>
              </ul>
              <Divider />
              <h3 className={styles.itemTitle}>Bebidas</h3>
              <ul>
                <li>
                  Batida Sem Álcool
                  <ul>
                    <li>Maracujá</li>
                    <li>Morango</li>
                    <li>Côco</li>
                  </ul>
                </li>
                <li>
                  Chopp Artesanal
                  <ul>
                    <li>Pilsen</li>
                    <li>APA</li>
                  </ul>
                </li>
                <li>
                  Refrigerante
                  <ul>
                    <li>Cola</li>
                    <li>Guaraná</li>
                    <li>Laranja</li>
                    <li>Soda</li>
                  </ul>
                </li>
                <li>
                  Suco Natural
                  <ul>
                    <li>Uva</li>
                    <li>Manga</li>
                  </ul>
                </li>
                <li>
                  Água
                  <ul>
                    <li>com ou sem gás</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className={styles.middleDivider}>
              <Divider />
            </div>
            <div className={styles.col}>
              <h3 className={styles.itemTitle}>Bartender</h3>
              <ul>
                <li>
                  Caipirinha
                  <ul>
                    <li>Vodka</li>
                    <li>Cachaça</li>
                    <li>Saquê</li>
                  </ul>
                  <b style={{ fontSize: "0.8rem" }}>Frutas</b>
                  <ul>
                    <li>Morango</li>
                    <li>Maracujá</li>
                    <li>Limão</li>
                    <li>Kiwi</li>
                    <li>Abacaxi</li>
                  </ul>
                </li>
                <li>
                  Aperol Spritz
                  <ul>
                    <li>Aperol, laranja e champagne</li>
                  </ul>
                </li>
                <li>
                  Gin Tônica
                  <ul>
                    <li>Gin, água tônica, limão, hortelã e alecrim</li>
                  </ul>
                </li>
                <li>
                  Sex On The Beach
                  <ul>
                    <li>
                      Vodka, suco de laranja, suco de pêssego, licor de pêssego
                      e grenadine
                    </li>
                  </ul>
                </li>
                <li>
                  Lagoa Azul
                  <ul>
                    <li>Vodka, licor de curaçau, soda e limão</li>
                  </ul>
                </li>
                <li>
                  Screw Driver
                  <ul>
                    <li>Vodka e suco de laranja</li>
                  </ul>
                </li>
                <li>
                  Ilha Tropical
                  <ul>
                    <li>Vodka, licor de curaçau blue e suco de abacaxi</li>
                  </ul>
                </li>
                <li>
                  Mojito
                  <ul>
                    <li>Rum branco, suco de limão, soda e folha de hortelã</li>
                  </ul>
                </li>
                <li>
                  <b>Sem Álcool</b>
                </li>
                <li>
                  Apple Delicious
                  <ul>
                    <li>Suco de maçã, grenadine de maça verde e gelo</li>
                  </ul>
                </li>
                <li>
                  Soda Italiana
                  <ul>
                    <li>Soda, grenadine e gelo</li>
                  </ul>
                </li>
              </ul>
              <Divider />
              <h3 className={styles.itemTitle}>Doces</h3>
              <ul>
                <li>Brigadeiro</li>
                <li>Beijinho</li>
                <li>Doce de Mamão</li>
                <li>Doce de Abóbora</li>
                <li>Musse de Maracujá</li>
                <li>
                  Bolo
                  <ul>
                    <li>chocolate com recheio de beijinho e morango</li>
                  </ul>
                </li>
              </ul>
              <Divider />
              <h3 className={styles.itemTitle}>Encerramento</h3>
              <ul>
                <li>Café com biscoito e docinhos</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardapioPage;
