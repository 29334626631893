
import classNames from 'classnames';
import { useMemo } from 'react';
import MenuComponent from '../../components/Menu';
import { Dates } from '../../consts/dates';
import useWindowSize from '../../hooks/useWindowSise';
import styles from './Home.module.scss';

const HomePage = () => {

    const windowSize = useWindowSize();

    const remainingDays = useMemo(() => {
        const difference = new Date(Dates.dateEn).getTime() - new Date().getTime();
        return Math.ceil(difference / (1000 * 3600 * 24));
    }, [])

    const remaingText: JSX.Element = useMemo(() => {
        if (remainingDays === 1) {
            <>É amanhã!</>
        }
        if (remainingDays > 0) {
            return <>Faltam {remainingDays < 30 && 'apenas'} <span className={styles.dias}>{remainingDays}</span> dias</>
        }
        if (remainingDays === 0) {
            return <>É hoje!</>
        }
        return <>Casamos há <span className={styles.dias}>{-remainingDays}</span> dia{remainingDays > 1 && 's'}</>
    }, [remainingDays]);

    return (
        <>
            <MenuComponent />
            <section className={styles.stage}>
                <div className={styles.bg}>
                    {
                        (
                            windowSize.width >= 1024 &&
                            <video autoPlay controls={false} loop muted>
                                <source src="/video.mp4" type='video/mp4' />
                            </video>
                        )
                        ||
                        <div className={styles.bgImg} style={{ backgroundImage: 'url("/img/bg-mobile.jpg")' }} />
                    }
                    <div className={styles.efeito} style={{ backgroundImage: 'url("/img/efeito.png")' }} />
                </div>
                <div className={styles.content}>
                    <h1 className={styles.title}>Kelvin <br /> &amp; <br /> Karla</h1>
                    <p className={classNames(styles.date, styles.fullDate)}>–•– {Dates.fullDate} –•–</p>
                    <p className={classNames(styles.date, styles.datePt)}>–•– {Dates.datePt} –•–</p>
                    {remainingDays > -1 && <p className={styles.time}>{Dates.time}</p>}
                    <p className={styles.remaingText}>{remaingText}</p>
                </div>
            </section>
        </>
    )
}

export default HomePage;