import useWindowSize from '../../hooks/useWindowSise';
import MenuComponent from '../Menu';
import styles from './Page.module.scss';

type Props = {
    children: React.ReactNode
}

const PageComponent = ({
    children
}: Props) => {

    const windowSize = useWindowSize();

    return <div className={styles.stage} style={{backgroundImage: windowSize.width > 1024 ? 'url("./img/bg-home.jpg")' : 'url("./img/bg-mobile.jpg")'}}>
        <div className={styles.overlay}>
            <MenuComponent />
            <div className="container">
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>
    </div>
}

export const Title = ({
    children
}: Props) => {

    return <div className={styles.title}>
        <div className={styles.folhasLeft}>
            <img src='./img/folhas.png' alt="Folhas"/>
        </div>
        <h1>{children}</h1>
        <div className={styles.folhasRight}>
            <img src='./img/folhas.png' alt="Folhas"/>
        </div>
    </div>
}

export const SubTitle = ({
    children
}: Props) => {
    return <div className={styles.subTitle}>
        <div className={styles.line}></div>
        <h2>{children}</h2>
        <div className={styles.line}></div>
    </div>
}

export default PageComponent;