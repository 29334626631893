import classNames from "classnames";
import { Link } from "react-router-dom";
import BoxBorder from "../../components/BoxBorder";
import Divider from "../../components/Divider";
import PageComponent, { SubTitle, Title } from "../../components/Page";
import { MenuTypes } from "../../consts/menu";
import styles from './ManualPadrinhos.module.scss';

const ManualPadrinhosPage = () => {

    return <PageComponent>
        <Title>{MenuTypes.consts.manual.label}</Title>

        <SubTitle>O dia está chegando!</SubTitle>

        <p>E em meio a tantos preparativos nos pegamos pensando em toda nossa trajetória até aqui, as conquistas, as dificuldades, os aprendizados, os momentos compartilhados e em todas as pessoas que fizeram parte de algum pedacinho importante da nossa história, aprendizado, amizade e carinho.</p>
        <p>E quando pensamos em como tornar esse dia perfeito sempre imaginamos ter você ao nosso lado, então se você disse sim para o convite de padrinho ou madrinha, aqui estão algumas coisinhas importantes para que tudo saia como planejado.</p>
        <p>Os padrinhos devem chegar ao local com pelo menos <b>1 hora de antecedência</b> e procurar pela nossa acessora, a <b>Mari</b>.</p>

        <SubTitle>Vestido da Madrinha</SubTitle>

        <div className="row align-items-center">
            <div className="col-lg-7">
                <p>Prepare-se para estar linda e combinando com o cenário natural do momento. Para te ajudar, elaboramos essa <strong> paleta de cores </strong> para você se basear ao escolher seu vestido, no modelo que preferir.</p>
                <p>Ah, essas cores são apenas uma referência, então se preferir um vestido de cor que não esteja na paleta, mas que seja próximo aos tons indicados, é só nos avisar ;).</p>
            </div>
            <div className="col-lg offset-xxl-1 text-center">
                <BoxBorder>
                    <img src="img/paleta.png" alt="Paleta" />
                </BoxBorder>
            </div>
        </div>

        <SubTitle>Traje do Padrinho</SubTitle>

        <p>Bom, a madrinha já sabe que deve estar deslumbrante, mas você, padrinho, não pode ficar para trás! E como sabemos que homens preferem praticidade, selecionamos um modelo único para nossos padrinhos por um valor bem bacana.</p>
        <p>O traje é uma camisa branca com calça e colete creme e gravata borboleta.</p>
        <p>Você só precisa ir até a loja <strong>Espaço São Caetano Noivos</strong>, na <a href="https://www.google.com/maps/place/R.+Florêncio+de+Abreu,+875+-+Centro+Histórico+de+São+Paulo,+São+Paulo+-+SP,+01029-001/@-23.5362951,-46.635491,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce58585d79f6dd:0xb997f955d39a2b8f!8m2!3d-23.5363!4d-46.633297" target="_blank" rel="noreferrer">Rua Florêncio de Abreu 875</a>, Luz – São Paulo. Recomendamos entrar em contato antes para verificar a disponibilidade para visita.</p>
        <p>Uma coisinha muito importante para não esquecer: <strong>Sua gravata deve ser da mesma cor do vestido da madrinha com quem você fará par!</strong> Leve uma foto do vestido ou indique a cor exata ao atendente da loja.</p>
        <p>Apresente o cartão que você recebeu junto com o manual impresso, tire suas medidas, sirva uma cerveja na sua tulipa personalizada e aguarde conosco!</p>

        <SubTitle>O Seu Papel</SubTitle>

        <ol className={classNames(styles.seuPapel, 'row', 'justify-content-center')}>
            <li className="col-md-6 col-lg-4 col-xl-3">Ter muita paciência com os noivos;</li>
            <li className="col-md-6 col-lg-4 col-xl-3">Zelar pela felicidade do casal;</li>
            <li className="col-md-6 col-lg-4 col-xl-3">Estar e sentir-se lindo(a) no dia;</li>
            <li className="col-md-6 col-lg-4 col-xl-3">Estar em contato para saber detalhes;</li>
            <li className="col-md-6 col-lg-4 col-xl-3">Saber sua importância para os noivos;</li>
            <li className="col-md-6 col-lg-4 col-xl-3">Sorrir bastante e tirar muitas fotos;</li>
            <li className="col-md-6 col-lg-4 col-xl-3">Participar ativamente da cerimônia e da festa;</li>
            <li className="col-md-6 col-lg-4 col-xl-3">Garantir a lua de mel na gravata/salto;</li>
            <li className="col-md-6 col-lg-4 col-xl-3">Estar sempre presente na vida do casal;</li>
            <li className="col-md-6 col-lg-4 col-xl-3">Se divertir muito!</li>
        </ol>

        <Divider />

        <p>Esperamos que esteja feliz com o convite e desejamos que seja presente não só no dia do nosso casamento, mas em nossas vidas. Você é importante para nós e não poderíamos querer pessoa melhor compartilhando esse momento conosco, celebrando o amor e felicidade.</p>
        <p>Acesse <Link to={MenuTypes.consts.avisos.link}>{MenuTypes.consts.avisos.menuLabel}</Link> para acompanhar todas as informações importantes e preparativos.</p>
        <p className="highlight text-center">Obrigado pelo sim e até o grande dia!</p>
    </PageComponent>
}

export default ManualPadrinhosPage;