
import { useCallback, useEffect, useState } from 'react';
import { mountEndpoint } from '../../consts/dirs';
import lsToken from '../../utils/token';
import styles from './Auth.module.scss';

type Props = {
    children: JSX.Element,
    setToken: React.Dispatch<React.SetStateAction<string | null>>,
    token: string | null,
    checkPermission: (res: Response) => Response
}

const AuthPage = ({
    children,
    setToken,
    token,
    checkPermission
}: Props): JSX.Element => {
    const [message, setMessage] = useState<string | null>(null)
    const [seinding, setSending] = useState<boolean>(false)

    const login = useCallback(async (event) => {
        event.preventDefault()

        if (seinding) {
            return
        }

        const inputs = event.target.elements
        const user = inputs.user.value
        const password = inputs.password.value

        setSending(true)

        await fetch(mountEndpoint('login.php'), {
            method: 'POST',
            body: JSON.stringify({ user, password }),
            headers: { "Content-type": "application/json", 'Accept': 'application/json' }
        }).then(res => {
            if (res.status === 401) {
                setMessage('Usuário ou senha incorretos')
                setToken(null)
                lsToken.remove()
                return null
            } else if (res.status === 200) {
                return res.json()
            }
        }).then(res => {
            if (res) {
                setToken(res.token)
                lsToken.set(res.token)
            }
        }).catch(err => {
            setMessage('Erro ao fazer login')
            console.error(err)
            setToken(null)
            lsToken.remove()
        })

        setSending(false)
    }, [setToken, setSending, seinding])

    useEffect(() => {
        if (token) {
            const headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            headers.append('token', token)

            fetch(mountEndpoint('check-session.php'), {
                method: 'POST',
                headers
            })
                .then(checkPermission)
                .then(res => {
                    if (res.status !== 200) {
                        setMessage('Sessão expirada. Faça login novamente.')
                    }
                })
        }
    }, [])

    return token ? children :
        <div className={styles.stage} style={{ backgroundImage: 'url("./img/bg-home.jpg")' }}>
            <form className={styles.form} onSubmit={login}>
                <h1>Área restrita</h1>
                <input name='user' placeholder='Usuário' />
                <input name='password' type="password" placeholder='Senha' />
                {message && <p className={styles.message}>{message}</p>}
                <button type='submit' disabled={seinding}>Login</button>
            </form>
        </div>


}

export default AuthPage;