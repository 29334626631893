import Divider from "../../components/Divider";
import PageComponent, { Title } from "../../components/Page";
import { MenuTypes } from "../../consts/menu";
import styles from './Presentes.module.scss';

const PresentesPage = () => {

    return <PageComponent>
        <Title>{MenuTypes.consts.lista.label}</Title>

        <Divider />

        <p>Primeiro: nós adoramos praticidade. Segundo: Ainda não fazemos ideia de quais móveis e eletrodomésticos queremos exatamente para nossa casa (afinal ainda queremos ampliar a casa antes de ampliar a família, se é que me entende). Por essas duas razões criamos formas rápidas e fáceis para recebermos nossos presentinhos em dinheiro.</p>
        <p>Você pode nos presentear com o valor que quiser via <b>PIX</b>, ou no cartão de crédito via <b>Paypal</b> ou <b>Mercado Pago</b>.</p>
        <p ><b>IMPORTANTE:</b> Ao presentar, insira <b>Presente de casamento</b> à mensagem ou comentário do pagamento; </p>

        <div className="row my-1">
            <div className="col-lg my-4">
                <div className={styles.boxPag}>
                    <div className={styles.boxPag_header}>
                        <img src="/img/icon-pix.png" alt="QR Code" />
                    </div>
                    <div className={styles.boxPag_body}>
                        <p>Escaneie o código abaixo</p>
                        <img className={styles.boxPag_qrCode} src="/img/qr-pix.png" alt="Icon Pix" />
                        <p>Ou <a rel="noreferrer" href="https://nubank.com.br/pagar/se73/J2mvwXyJEd" target="_blank">clique aqui</a> para acessar.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg my-4">
                <div className={styles.boxPag}>
                    <div className={styles.boxPag_header}>
                        <img src="/img/icon-mp.png" alt="Icon Mercado Pago" />
                    </div>
                    <div className={styles.boxPag_body}>
                        <p>Selecione o valor com que deseja presentar</p>
                        <ul className={styles.boxPag_opcoes}>
                            <li><a rel="noreferrer" target="_blank" href="https://mpago.la/1M6MeHT">R$ 50</a></li>
                            <li><a rel="noreferrer" target="_blank" href="https://mpago.la/1qwj1qU">R$ 100</a></li>
                            <li><a rel="noreferrer" target="_blank" href="https://mpago.la/27Ararx">R$ 500</a></li>
                            <li><a rel="noreferrer" target="_blank" href="https://mpago.la/1QiJhJY">R$ 1.000</a></li>
                            <li><a rel="noreferrer" target="_blank" href="https://mpago.la/2KvH6rP">R$ 2.000</a></li>
                            <li><a rel="noreferrer" target="_blank" href="https://mpago.la/1n9K1g5">R$ 3.000</a></li>
                            <li><a rel="noreferrer" target="_blank" href="https://mpago.la/1az6ZRD">R$ 4.000</a></li>
                            <li><a rel="noreferrer" target="_blank" href="https://mpago.la/2P5jn1J">R$ 5.000</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg my-4">
                <div className={styles.boxPag}>
                    <div className={styles.boxPag_header}>
                        <img src="/img/icon-pp.png" alt="Icon Paypal" />
                    </div>
                    <div className={styles.boxPag_body}>
                        <p>Escaneie o código abaixo</p>
                        <img className={styles.boxPag_qrCode} src="/img/qr-pp.png" alt="QR Code" />
                        <p>Ou <a rel="noreferrer" href="https://www.paypal.com/donate/?business=PZZ6KCRUF34EQ&no_recurring=1&item_name=Presente+de+casamento&currency_code=BRL" target="_blank">clique aqui</a> para acessar.</p>
                    </div>
                </div>
            </div>
        </div>

        <p className="highlight">Nenhum dos meios acima funciona para mim. E agora?</p>
        <p>Se preferir, podemos gerar um <b>carnê</b> de até 12 boletos ou passar o cartão de crédito na <b>maquininha</b> em até 6 vezes.</p>
        <p>Você também terá a oportunidade de nos presentear durante o evento, na gravata ou no salto, por meio de dinheiro, pix ou cartão de crédito.</p>
        <p>Basta entrar em contato conosco e veremos a melhor maneira para você!</p>
    </PageComponent>
}

export default PresentesPage;