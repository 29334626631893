import { useCallback, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MenuTypes } from "./consts/menu";
import AuthPage from "./pages/Auth";
import AvisosPage from "./pages/Avisos";
import ConvitePage from "./pages/Convite";
import CadastroConvitePage from "./pages/Convite/Cadastro";
import HomePage from "./pages/Home";
import LocalPage from "./pages/Local";
import ManualPadrinhosPage from "./pages/ManualPadrinhos";
import PresentesPage from "./pages/Presentes";
import lsToken from "./utils/token";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardapioPage from "./pages/Cardapio";
import PreWeddingPage from "./pages/PreWedding";

const App = () => {

  const [token, setToken] = useState<string | null>(lsToken.get())

  const checkPermission = useCallback((res: Response): Response => {
    if (res.status !== 200) {
      lsToken.remove();
      setToken(null)
    }
    return res
  }, [])

  return <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path={MenuTypes.consts.manual.link} element={<ManualPadrinhosPage />} />
        <Route path={MenuTypes.consts.avisos.link} element={<AvisosPage />} />
        <Route path={MenuTypes.consts.lista.link} element={<PresentesPage />} />
        <Route path={MenuTypes.consts.local.link} element={<LocalPage />} />
        <Route path={MenuTypes.consts.cardapio.link} element={<CardapioPage />} />
        <Route path={MenuTypes.consts.preWedding.link} element={<PreWeddingPage />} />
        <Route path={`${MenuTypes.consts.convite.link}/:id`} element={<ConvitePage />} />
        <Route path='/cadastro-convite' element={
          <AuthPage token={token} setToken={setToken} checkPermission={checkPermission}>
            <CadastroConvitePage token={token as string} checkPermission={checkPermission} />
          </AuthPage>
        } />
      </Routes>
    </BrowserRouter>
    <ToastContainer
      position="bottom-right"
    />
  </>
}

export default App;