

import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { MenuTypes } from '../../consts/menu';
import styles from './Menu.module.scss';

const MenuComponent = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const pathName = useLocation().pathname;
    const isHome = useMemo(() => pathName === '/', [pathName]);

    return (
        <div className={classNames(styles.stage, {
            [styles.isHome]: isHome
        })}>
            <div className={classNames('container', styles.container)}>
                <nav>
                    {!isHome && <Link to='/' className={styles.home}>Kelvin &amp; Karla</Link>}
                    <div className={styles.menuWrapper}>
                        <button onClick={() => setMenuOpen(!menuOpen)} className={styles.openMenu}><img src="/img/menu-icon.png" alt="Menu Icon" /></button>
                    </div>
                    <ul className={classNames({
                        [styles.isHome]: isHome,
                        [styles.menuOpen]: menuOpen,
                    })}>
                        {Object.values(MenuTypes.consts).filter(({ hide }) => !hide).map(({ link, menuLabel }) => <li key={`link-${link}`}> <NavLink className={({isActive}) => classNames({[styles.active]: isActive})} onClick={() => setMenuOpen(false)} to={link}>{menuLabel}</NavLink> </li>)}
                    </ul>
                </nav>
            </div>
        </div>

    )
}

export default MenuComponent;