import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dates } from "../../consts/dates";
import { mountEndpoint } from "../../consts/dirs";
import useWindowSize from "../../hooks/useWindowSise";
import styles from './Convite.module.scss';
import { Invite } from "./Invite.model";

const ConvitePage = () => {
    const { id } = useParams()
    const [invite, setInvite] = useState<Invite | null>(null)
    const [feedbackText, setFeedbackText] = useState<string | JSX.Element>('Carregando convite...')

    useEffect(() => {
        fetch(mountEndpoint(`get-invite.php?id=${id}`))
            .then(res => {
                if (res.status === 404) {
                    setFeedbackText(<>Este convite não existe ou foi removido.<br/> Contacte os noivos para mais informações.</>)
                    return null
                }
                return res.json()
            })
            .then((invite: Invite) => setInvite(invite))
            .catch(err => {
                setFeedbackText(<>Erro ao exibir o convite.<br /> Contacte os noivos caso o problema persista.</>)
                console.log(err)
            })
    }, [])

    const windowSize = useWindowSize();

    return <div className={styles.stage}>
        <div className={styles.convite}>
            <div className={styles.conviteInner}>
                {
                    invite ?
                        <>
                            <img src="/img/brasao.png" alt="Brasão" className={styles.brasao} />
                            <h1 className={styles.title}>Kelvin &amp; Karla</h1>

                            <p>Com muito prazer, convidamos para nosso casamento</p>
                            <p className={styles.convidado}>{invite.nome}</p>
                            {invite.nota && <p className={styles.convidadoObs}>{invite.nota}</p>}
                            {
                                windowSize.width >= 650 ?
                                    <p>–•– Dia {Dates.fullDate}, {Dates.day}, às {Dates.time} –•–</p> :
                                    <p className={styles.date}>–•– {Dates.datePt} –•– <br /> {Dates.time}</p>
                            }
                            
                            <p><strong>Sítio Della Torre</strong><br /><a href="https://www.google.com.br/maps/place/S%C3%ADtio+Della+Torre/@-23.298758,-46.5694025,17z/data=!4m5!3m4!1s0x94ceedb3abfe6b33:0x147842871e75b3a7!8m2!3d-23.2992036!4d-46.5670108" target="_blank" rel="noreferrer">Estrada Municipal, 270 - Jardim Sandra, Mairiporã – SP</a></p>
                            <br />
                            <p className={styles.rodape}>Para presentes e maiores informações, <a href="https://www.casamento.kelvinmarques.com.br">clique aqui</a> e acesse nosso site.</p>
                            <p className={styles.rodape}>Este convite não é necessário para entrar no evento.</p>
                        </> :
                        <p>{feedbackText}</p>
                }
            </div>
        </div>
    </div>
}

export default ConvitePage;