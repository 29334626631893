import styles from './BoxBorder.module.scss';

interface Props {
    children: React.ReactNode
}

const BoxBorder = ({ children }: Props) => {
    return <div className={styles.boxBorder}>
        {children}
    </div>
}

export default BoxBorder