export namespace MenuTypes {

  type MenuItems = 'avisos' | 'manual' | 'local' | 'convite' | 'lista' | "cardapio" | "preWedding";

  type Item = {
    link: string,
    label?: string,
    menuLabel?: string,
    hide?: boolean
  }

  export const consts: Record<MenuItems, Item> = {
    avisos: {
      link: '/avisos-importantes',
      label: 'Avisos importantes',
      menuLabel: 'Avisos importantes',
    },
    manual: {
      link: '/manual-dos-padrinhos',
      label: 'Manual dos padrinhos',
      menuLabel: 'Manual dos padrinhos',
    },
    local: {
      link: '/local',
      label: 'Local',
      menuLabel: 'Local',
    },
    convite: {
      link: '/convite',
      label: 'Meu convite',
      menuLabel: 'Meu convite',
      hide: true,
    },
    lista: {
      link: '/presentear',
      label: 'Presentear',
      menuLabel: 'Presentear',
    },
    cardapio: {
      link: '/cardapio',
      hide: true,
    },
    preWedding: {
      link: '/pre-wedding',
      label: 'Pré-wedding',
      menuLabel: 'Pré-wedding',
    }
  }
}