import BoxBorder from "../../components/BoxBorder";
import Divider from "../../components/Divider";
import PageComponent, { SubTitle, Title } from "../../components/Page";
import { MenuTypes } from "../../consts/menu";
import styles from './Avisos.module.scss';

const AvisosPage = () => {

    return <PageComponent>
        <Title>{MenuTypes.consts.avisos.label}</Title>
        <SubTitle>Vestimentas</SubTitle>
        <p>Como você já deve saber, nosso casamento será no campo. E no local do tão esperado “Sim” você terá contato com gramado, local aberto, sol.. </p>
        <p>Por isso, achamos importante trazer algumas dicas para que você aproveite ao máximo junto com a gente:</p>
        <p>• <b>Queremos todos bem confortáveis</b>, e por isso recomendamos o padrão <b>esporte fino</b> como vestimenta principal. Fique livre para escolher o melhor modelo, mas não se esqueça de verificar como estará o tempo no dia (esperamos que seja calor).</p>
        <p>• <b>Evite salto agulha e muito altos</b>: você andará em pisos irregulares e gramado. Uma boa opção são saltos grossos e plataformas, assim conseguirá andar sem torcer o pé e ficará mais confortável. </p>
        <p>• <b>Atenção mulherada!</b> Procuramos agendar a cerimônia em um horário que o sol já esteja ameno, mas por precaução (dica de maquiadora hein!) procure usar maquiagens leves, a prova d’água e use protetor solar.</p>
        <p>• <b>Leve um agasalho.</b> O evento começará à tarde, mas irá até o início da noite, ou seja, a temperatura pode cair bastante. Por isso lembre-se de levar um agasalho para poder curtir independentemente do clima.</p>
        <Divider />
        <p>Para ajudar na escolha de sua roupa, preparamos algumas orientações para você:</p>
        <div className="row">
            <div className="col-lg">
                <h3>Masculino</h3>
                <BoxBorder>
                    <img src="/img/traje-masculino.jpg" alt="Traje Masculino" />
                </BoxBorder>
                <ul className="list">
                    <li>Não utilize tons de vermelho escarlate (tons de vinho e rosa são aceitáveis).</li>
                    <li>A cor branca é aceitável para camisa/camiseta e calçado apenas.</li>
                    <li>Se optar por bermuda, utilizar um modelo skinny sem estampa e combinar com camisa social de botão e gola.</li>
                    <li>Se optar por camiseta, utilizar um modelo sem estampa e combinar com blazer e calça skinny.</li>
                    <li>Não utilize tênis de corrida.</li>
                    <li>Utilize colete e/ou gravata se preferir, mas combine com calça e camisa de botão e gola.</li>
                    <li>Utilize terno completo se preferir.</li>
                </ul>
            </div>
            <div className="col-lg">
                <h3>Feminino</h3>
                <BoxBorder>
                    <img src="/img/traje-feminino.jpg" alt="Traje Feminino" />
                </BoxBorder>
                <ul className="list">
                    <li>Não utilize a cor branca ou tons de vermelho escarlate (tons de vinho e rosa são aceitáveis).</li>
                    <li>Não utilize salto agulha e evite salto muito alto.</li>
                    <li>Utilize sandalha "rasteirinha" se preferir.</li>
                    <li>Se optar por calçado fechado, prefira sapatilhas.</li>
                </ul>
            </div>
        </div>
        <SubTitle>Vai de carro?</SubTitle>
        <p>Não se preocupe com estacionamento, temos vagas no local.</p>
        <p>Lembre-se sempre de <b>conferir os valores de pedágio</b>. Já podemos adiantar que se a rota sugerida for da capital de São Paulo pela Fernão Dias, passará por um pedágio no valor de R$ 2,30.</p>
        <p>Dirigir com segurança inclui verificar as condições do veículo para a viagem. Antes de sair, cheque o óleo, água do radiador, freios, luzes (seta, ré, freios e faróis), lipadores e água do parabrisa.</p>
        <SubTitle>Se beber, não dirija!</SubTitle>
        <p>Sabemos que nossa família e amigos curtem comemorar com uma gelada na mão, e nós também curtimos. Serviremos bebidas alcoólicas como cerveja e drinks para desfrutar melhor da festa, porém, lembramos que nosso casamento ocorrerá no interior de São Paulo. Sabemos que a maioria usará rodovias para retornarem para suas casas, o que por si só já representa um risco. </p>
        <p>Por isso indicamos algumas opções de hospedagens próximas ao local do evento para que descanse até o dia seguinte. Queremos uma festa incrível, mas também queremos que todos cheguem em segurança em casa.</p>
        <div className="row">
            <div className="col-lg-4 my-4">
                <img src="/img/hotel-graal.jpg" alt="Graal Inn Mairiporã" className={styles.hotel} />
                <p className="highlight color-laranja m-2">Graal Inn</p>
                <p><a href="https://www.google.com/maps/place/Graal+Inn+Mairiporã/@-23.2918361,-46.5750308,15z/data=!4m9!3m8!1s0x0:0x198e8ed015cd9db7!5m3!1s2022-04-03!4m1!1i2!8m2!3d-23.2918361!4d-46.5750308" target="_blank" rel="noreferrer">Via de Acesso Norte KM 62, Rod. Fernão Dias</a></p>
                <p>1,4 km de distância do evento<br /> Faixa de preço da diária: <b>R$ 160</b></p>
            </div>
            <div className="col-lg-4 my-4">
                <img src="/img/hotel-top.jpg" alt="Pousada TOP Mairiporã" className={styles.hotel} />
                <p className="highlight color-laranja m-2">Pousada TOP</p>
                <p><a href="https://www.google.com/maps/place/Pousada+TOP+Mairiporã/@-23.3105292,-46.5719715,17z/data=!3m1!4b1!4m9!3m8!1s0x94ceedb95ca6a44f:0xdc4d7f1264e63661!5m3!1s2022-04-03!4m1!1i2!8m2!3d-23.3105341!4d-46.5697775" target="_blank" rel="noreferrer">R. 21 de Abril, 128 - Jd odorico</a></p>
                <p>1,7 km de distância do evento<br /> Faixa de preço da diária: <b>R$ 210</b></p>
            </div>
            <div className="col-lg-4 my-4">
                <img src="/img/hotel-oluap.jpg" alt="Pousada e hotel Oluap" className={styles.hotel} />
                <p className="highlight color-laranja m-2">Pousada e hotel Oluap</p>
                <p><a href="https://www.google.com/maps/place/Pousada+e+hotel+em+mairiporã+Oluap/@-23.3208604,-46.5957007,17z/data=!3m1!4b1!4m9!3m8!1s0x94ceef9d7ae6d621:0x9a2304d024db8cdc!5m3!1s2022-04-03!4m1!1i2!8m2!3d-23.3208755!4d-46.5935057" target="_blank" rel="noreferrer">R. Ipiranga, 590 - Vila Ipanema</a></p>
                <p>4,6 km de distância do evento <br /> Faixa de preço da diária: <b>R$ 160</b></p>
            </div>
        </div>
        <p>Você também pode combinar com os convidados próximos de você para irem juntos e o motorista se compromete a não beber. Isso pode ser uma opção fácil e mais barata.</p>
        <p>Outra opção é juntar uma galera e rachar a locação de um micro ônibus ou van. Assim vocês podem curtir a festa ao máximo e voltar em total segurança. Com o preço da gasolina acho que compensa hein? </p>
        <SubTitle>Pandemia? Só na história</SubTitle>
        <p>A pandemia já é praticamente passado e estamos vivendo normalmente, mas não podemos nos descuidar totalmente. Caso você ainda não tenha tomado as primeiras duas doses da vacina contra o COVID-19, recomendamos que as providencie o quanto antes e previna o contágio durante o evento.</p>
        <p><b>VACINE-SE!</b> Por você, pelos seus familiares e amigos. Vacinas salvam vidas.</p>
        <SubTitle>Casamento amigo dos animais (cruelty free)</SubTitle>
        <p>Temos orgulho de ser um casal que se compromete com a causa animal e excluímos de nossas vidas tudo que custe a liberdade, integridade ou a vida de qualquer animal, e nosso casamento não poderia ser diferente. Por isso nosso cardápio será <b>100% vegano</b>. Quem nos conhece sabe que não passamos vontade, e conseguimos rechear nossa alimentação com opções deliciosas e livre de qualquer derivado animal. </p>
        <p>Com muito amor e carinho, juntamente com o Chef do Della Torre, muito experiente por sinal, montamos um cardápio incrível e bem flexível para que todos possam comer bem e curtir um pouquinho da nossa filosofia de vida junto com a gente. 😊</p>
        <p>E claro, estamos sempre abertos a sugestões. Então caso você tenha alguma ideia de comidinhas veganas que goste e ache uma boa opção para incluirmos no cardápio, manda pra gente que vamos avaliar. Adoraremos ter essa troca com você!</p>
    </PageComponent>
}

export default AvisosPage;