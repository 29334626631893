import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { mountEndpoint } from '../../consts/dirs';
import styles from './Cadastro.module.scss';
import { Invite } from './Invite.model';

type Props = {
    token: string,
    checkPermission: (res: Response) => Response,
}

const CadastroConvitePage = ({
    token,
    checkPermission,
}: Props) => {

    const [invites, setInvites] = useState<Invite[]>([])
    const [editing, setEditing] = useState<Invite | null>(null)
    const [deleting, setDeleting] = useState<Invite | null>(null)
    const [copied, setCopied] = useState<string | null>(null)
    const [search, setSearch] = useState<string>('')

    const headers = useMemo(() => {
        const _headers = new Headers()

        _headers.append("Content-type", "application/json")
        _headers.append("Accept", "application/json")
        _headers.append('token', token)

        return _headers
    }, [])

    const refresh = useCallback(() => {
        const fetchPromise = new Promise((resolve, reject) => {
            fetch(mountEndpoint('get-invites.php'), { headers })
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                    return res
                }
                throw new Error()
            })
            .then(res => res.json())
            .then((invites: Invite[]) => {
                setInvites(invites.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0)))
            })
            .catch(err => {
                reject(err)
                console.error(err)
            })
        })

        toast.promise(fetchPromise, {
            pending: 'Aguarde',
            error: 'Erro ao listar os convidados'
        })
    }, [setInvites, checkPermission, headers])

    const remove = useCallback(({ id, nome }: Invite) => {
        const fetchPromise = new Promise((resolve, reject) => {
            fetch(mountEndpoint('delete-invite.php'), {
                method: 'DELETE',
                body: JSON.stringify({ id }),
                headers
            })
                .then(checkPermission)
                .then(res => {
                    if (res.status === 200) {
                        refresh()
                        resolve(res)
                        return
                    }
                    throw new Error()
                })
                .catch(err => {
                    reject(err)
                    console.error(err)
                })
        })

        toast.promise(fetchPromise, {
            pending: `Apagando convite de ${nome}`,
            success: 'Convite apagado com sucesso!',
            error: 'Erro ao apagar o convite.'
        })
    }, [refresh, checkPermission, headers])

    const update = useCallback((invite: Invite) => {
        const fetchPromise = new Promise((resolve, reject) => {
            fetch(mountEndpoint('edit-invite.php'), {
                method: 'PUT',
                body: JSON.stringify(invite),
                headers
            })
                .then(checkPermission)
                .then(res => {
                    if (res.status === 200) {
                        setEditing(null)
                        refresh()
                        resolve(res)
                        return
                    }
                    throw new Error()
                })
                .catch(err => {
                    reject(err)
                    console.error(err)
                })
        })

        toast.promise(fetchPromise, {
            pending: 'Editando convite',
            success: 'Convite editado!',
            error: 'Erro ao editar o convite.'
        })
    }, [refresh, setEditing, checkPermission, headers])

    const create = useCallback((event) => {
        event.preventDefault()
        const inputs = event.target.elements
        const nome = inputs.nome.value
        const nota = inputs.nota.value

        if (nome.trim() === '') {
            toast.error('Informe ao menos um nome para adicionar um convite')
            return
        }

        const fetchPromise = new Promise((resolve, reject) => {
            fetch(mountEndpoint('post-invite.php'), {
                method: 'POST',
                body: JSON.stringify({ nome, nota }),
                headers
            })
                .then(checkPermission)
                .then(res => {
                    if (res.status === 200) {
                        (document.getElementById('formCreate') as HTMLFormElement)?.reset()
                        refresh()
                        resolve(res)
                        return
                    }
                    throw new Error()
                }).catch(err => {
                    reject(err)
                    console.error(err)
                })
        })

        toast.promise(fetchPromise, {
            pending: 'Criando convite',
            success: 'Convite cadastrado!',
            error: 'Erro ao cadastrar o convite.'
        })
    }, [refresh, checkPermission, headers])

    useEffect(() => {
        refresh()
    }, [])

    const onChangeInput = useCallback((prop: string, value: string, editingInvite: Invite) => {
        setEditing({
            ...editingInvite,
            [prop]: value
        })
    }, [])

    const copyLink = useCallback((id: string) => {
        navigator.clipboard.writeText(`https://casamento.kelvinmarques.com.br/convite/${id}`);
        setCopied(id)
    }, [setCopied])

    return (
        <div className={styles.stage}>
            <form id="formCreate" onSubmit={create} className={styles.formCreate}>
                <h2>Cadastrar convite</h2>
                <input className={styles.input} name="nome" placeholder="Nome" maxLength={100} />
                <input className={styles.input} name="nota" placeholder="Familiares ou observação" maxLength={300} />
                <button className={styles.button} type="submit">Cadastrar</button>
            </form>

            <div className={styles.busca}>
                <input className={styles.input} placeholder="Busca: Digite nome ou observação" onChange={(event) => setSearch(event.target.value)} /> <button className={styles.button}>Limpar</button>
            </div>

            <table className={styles.table} cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th style={{ width: '30%' }}>Nome</th>
                        <th style={{ width: '30%' }}>Familiares/observação</th>
                        <th style={{ width: '20%' }}>Link</th>
                        <th style={{ width: '20%' }}>Opções</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        invites
                            .filter(invite => {
                                const trimmedSearch = search.trim().toLowerCase()
                                if (search.trim() === "") {
                                    return invite
                                }
                                return invite.nome.toLowerCase().includes(trimmedSearch) ||
                                       invite.nota.toLowerCase().includes(trimmedSearch) 
                            })
                            .map(invite => {
                                return <tr key={invite.id}>
                                    <td>
                                        {
                                            editing?.id !== invite.id ?
                                                invite.nome :
                                                <input className={styles.input} value={editing.nome} onChange={event => onChangeInput('nome', event.target.value, editing)} />
                                        }
                                    </td>
                                    <td>
                                        {
                                            editing?.id !== invite.id ?
                                                invite.nota :
                                                <input className={styles.input} value={editing.nota} onChange={event => onChangeInput('nota', event.target.value, editing)} />
                                        }
                                    </td>
                                    <td>
                                        <button className={styles.button} onClick={() => window.open(`/convite/${invite.id}`)}>Visualizar</button>
                                        <button className={styles.button} onClick={() => copyLink(invite.id)}>{copied !== invite.id ? 'Copiar' : 'Copiado!'}</button>
                                    </td>
                                    <td>
                                        {
                                            editing?.id !== invite.id ?
                                                deleting?.id === invite.id ?
                                                    <div className={styles.apagar}>
                                                        <p>Apagar este convite?</p>
                                                        <button className={styles.button} onClick={() => remove(invite)}>Sim</button>
                                                        <button className={styles.button} onClick={() => setDeleting(null)}>Não</button>
                                                    </div>
                                                    :
                                                    <>
                                                        <button className={styles.button} onClick={() => setDeleting(invite)}>Apagar</button>
                                                        <button className={styles.button} onClick={() => setEditing({ ...invite })}>Editar</button>
                                                    </>
                                                :
                                                <>
                                                    <button className={styles.button} onClick={() => update(editing)}>Salvar</button>
                                                    <button className={styles.button} onClick={() => setEditing(null)}>Cancelar</button>
                                                </>
                                        }
                                    </td>
                                </tr>
                            })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default CadastroConvitePage;